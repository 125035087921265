<template>
  <div class="about">
    <loader v-if="loading"></loader>
    <div v-else>
      <table-component
        :items="items"
        :edit="false"
        :delet="false"
        :button-show="false"
        :show-error-dialog="false"
      >
        <template>
          <v-text-field
            v-model="searchInput"
            label="GuID"
            required
          ></v-text-field>
          <v-btn color="success" @click="setBody"> ПОИСК </v-btn>
        </template>
      </table-component>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/Loader";
import TableComponent from "@/components/TableComponent";
import astor from "@/model/astor.js";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    TableComponent,
    Loader,
  },

  data() {
    return {
      loading: true,
      searchInput: "",
      skuHead: [
        { id: 0, name: "ID" },
        { id: 1, name: "guid" },
        { id: 2, name: "Цена" },
        { id: 3, name: "Баланс" },
        { id: 4, name: "Ед.измерения" },
        { id: 5, name: "Название файла" },
        { id: 6, name: "Дата обновления" },
        { id: 7, name: "Филиал" },
        { id: 8, name: "Акция" },
      ],
      productHead: [
        { id: 0, name: "ID" },
        { id: 1, name: "ID Товара" },
        { id: 2, name: "Категория" },
        { id: 3, name: "guid" },
        { id: 4, name: "Штрихкод" },
        { id: 5, name: "Название" },
        { id: 6, name: "Описание" },
        { id: 7, name: "Ед.измерения" },
        { id: 8, name: "Img" },
        { id: 9, name: "Название файла" },
        { id: 10, name: "Дата обновления" },
      ],
      items: {
        head: [],
        body: [],
      },
    };
  },

  computed: {
    ...mapGetters({
      ASTOR: "Astor/STATE",
    }),
  },

  watch: {
    async $route() {
      await this.setHead();
      await this.setBody();
    },
  },

  async created() {
    await this.checkPower();
    await this.setBody();
    await this.setHead();
    this.loading = false;
  },

  methods: {
    ...mapActions({
      getSkuChanges: "Astor/GET_SKU_PRICE_CHANGES",
      getProductChanges: "Astor/GET_PRODUCT_CATALOG_CHANGES",
    }),
    checkPower() {
      let state = this.$power.check(this.$route.meta.power);
      if (state === false) {
        // this.$router.push("/");
      }
    },
    async setHead() {
      switch (this.$route.meta.type) {
        case "sku":
          this.items.head = this.skuHead;
          break;
        case "product":
          this.items.head = this.productHead;
          break;
      }
    },
    async setBody() {
      const pageType = this.$route.meta.type;
      let model = new astor();
      if (pageType === "sku") {
        await this.getSkuChanges({ guid: this.searchInput });
        this.items.body = model.setBody(this.ASTOR.skuPricesChanges, pageType);
      } else if (pageType === "product") {
        await this.getProductChanges({ guid: this.searchInput });
        this.items.body = model.setBody(
          this.ASTOR.productCatalogChanges,
          pageType
        );
      }
    },
  },
};
</script>
